import * as React from "react"
import styled from "styled-components"
import Icon, { icon } from "../../shared/Icon/Icon"
import {
  color,
  device,
  getBackgroundColor,
  screenSize,
} from "../../GlobalStyles"
import { InvitationInterface } from "./invitation.interface"
import { parseTextLink } from "../../../utils/parseTextLink"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"

const Color = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
  width: 100%;
  margin-bottom: 0px;

  @media ${device.tablet} {
    margin-bottom: 35px;
  }
`
const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 1240px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 40px 20px;

  @media ${device.tablet} {
    padding: 0 20px;
    min-height: 464px;
  }
`
const Image = styled.div`
  .art-directed {
    @media screen and (max-width: ${screenSize.tablet - 1}px) {
      display: none;
    }
  }
`

const Text = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 1.125rem;

  @media ${device.laptop} {
    font-size: 1.25rem;
  }

  @media ${device.laptopL} {
    font-size: 1.5rem;
  }
`

const Text1 = styled(Text)`
  padding-bottom: 30px;
`

const Text2 = styled(Text)`
  margin-left: 30px;
`

const Text3 = styled(Text)`
  padding-bottom: 30px;
  padding-right: 30px;

  @media ${device.tablet} {
    padding-right: 0px;
  }
`

const Info = styled.div`
  font-family: Open sans;
  font-size: 1rem;
  display: grid;
  grid-template-columns: auto auto;
  align-items: start;
  justify-content: start;
  grid-gap: 20px;
  padding: 10px 0;

  span {
    color: ${color.primary};
  }

  @media ${device.laptop} {
    font-size: 1.25rem;
  }

  a {
    color: ${color.primary};

    :hover {
      color: ${color.primaryHover};
    }
  }
`

const Block = styled.div`
  position: relative;
  display: block;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
  margin: 0 auto;

  @media ${device.tablet} {
    display: inline-block;
    width: 50%;
    padding-top: 65px;
    vertical-align: top;
    margin: 0;
  }
`

const BlockLeft = styled(Block)`
  padding-top: 50px;
  max-width: 279px;

  @media ${device.tablet} {
    padding-left: 70px;
    padding-right: 160px;
    max-width: unset;
  }
`

const BlockRight = styled(Block)`
  padding-top: 0;

  @media ${device.tablet} {
    padding-top: 65px;
    padding-right: 0;
    padding-left: 160px;
  }
`

const InfoWrapper = styled.div`
  padding: 0 15px;
  margin: 0 auto;

  @media ${device.mobileM} {
    padding: 0 40px;
  }

  @media ${device.tablet} {
    padding: 0;
    margin: 0;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  text-align: center;
  padding: 25px 0;
  z-index: 1;

  @media ${device.tablet} {
    position: absolute;
    top: 65px;
    transform: translateX(-50%);
    left: 50%;
    padding: 0;
    z-index: 0;
  }
`

const Question = styled.div`
  position: absolute;
  top: 20px;
  left: 30%;
  transform: translateX(-50%);

  @media ${device.tablet} {
    top: 20px;
    left: 0;
    transform: unset;
  }
`

const Invitation = ({
  color,
  text1,
  text2,
  text3,
  aboutText1,
  aboutText2,
  image,
}: InvitationInterface) => {
  const invitationImage = getImage(image.image)
  return (
    <Color color={getBackgroundColor(color)}>
      <Wrapper>
        <BlockLeft>
          <Text1>{text1}</Text1>
          <Text2>{text2}</Text2>
        </BlockLeft>

        <ImageWrapper>
          <Image>
            {invitationImage && (
              <GatsbyImage
                image={invitationImage}
                alt={image.alt}
                className="art-directed"
              />
            )}
          </Image>
        </ImageWrapper>

        <BlockRight>
          <Text3>{text3}</Text3>
          <InfoWrapper>
            <Info>
              <Icon type={icon.arrow_forward} />
              <div>{parseTextLink(aboutText1)}</div>
            </Info>
            <Info>
              <Icon type={icon.arrow_forward} />
              <div>{parseTextLink(aboutText2)}</div>
            </Info>
          </InfoWrapper>
        </BlockRight>

        <Question>
          <Icon type={icon.question} />
        </Question>
      </Wrapper>
    </Color>
  )
}

export default Invitation
