import * as React from "react"
import ContactForm from "../sections/Form/contactForm"
import { BusinessOfferInterface } from "./businessOffer.interface"
import Reference from "../sections/Reference/Reference"
import Info from "../sections/Info/Info"
import Invitation from "../sections/Invitation/Invitation"
import OfferCards from "../sections/OfferCards/OfferCards"
import Hero from "../sections/Hero/Hero"

const BusinessOffer = ({
  businessOffer: { hero, referenceS, offerCardsS, invitationS, infoS, formS },
}: {
  businessOffer: BusinessOfferInterface
}) => {
  return (
    <>
      {hero.show && <Hero {...hero} />}
      {offerCardsS.show && <OfferCards {...offerCardsS} />}
      {invitationS.show && <Invitation {...invitationS} />}
      {infoS.show && <Info {...infoS} />}
      {referenceS.show && <Reference {...referenceS} />}
      {formS.show && <ContactForm {...formS} />}
    </>
  )
}

export default BusinessOffer
