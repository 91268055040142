import * as React from "react"
import { RelocationInterface } from "./relocation.interface"
import ContactForm from "../../sections/Form/contactForm"
import Reference from "../../sections/Reference/Reference"
import Info from "../../sections/Info/Info"
import PriceFactor from "../../sections/PriceFactor/PriceFactor"
import Package from "../../sections/Package/Package"
import Hero from "../../sections/Hero/Hero"

const Relocation = ({
  businessOfferRelocation: {
    hero,
    packageS,
    priceFactorS,
    infoS,
    referenceS,
    formS,
  },
}: {
  businessOfferRelocation: RelocationInterface
}) => {
  return (
    <>
      {hero.show && <Hero {...hero} />}
      {packageS.show && <Package {...packageS} />}
      {priceFactorS.show && <PriceFactor {...priceFactorS} />}
      {infoS.show && <Info {...infoS} />}
      {referenceS.show && <Reference {...referenceS} />}
      {formS.show && <ContactForm {...formS} />}
    </>
  )
}

export default Relocation
