import React, { useState } from "react"
import { Field, Formik, FormikHelpers, ErrorMessage } from "formik"
import Button from "../../shared/Button/Button"
import {
  ContactFormInterface,
  formdata,
  ValuesInterface,
} from "./contactForm.interface"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import styled from "styled-components"
import { DayPicker } from "react-day-picker"
import "react-day-picker/dist/style.css"
import { format, isValid } from "date-fns"
import { validate } from "./validate"
import { backgroundColor, color, device, screenSize } from "../../GlobalStyles"
import Title from "../../shared/Title/Title"
import { navigate } from "gatsby"
import { useFormContext } from "../../../store/contact-form-ref-context"

const InputCss = `
  box-sizing: border-box!important;
  display: block!important;
  width: 100%!important;
  font-size: 0.875rem!important;
  padding-left: 13px;
  padding-right: 13px!important;
  padding-top: 9px!important;
  padding-bottom: 5px!important;
  color: #ffffff!important;
  background-color: rgb(255 255 255 / 26%)!important;
  border-radius: unset!important;
  border-color: #ffffff4f!important;
  border-width: thin!important;
  border-style: revert!important;
  font-family: Open Sans!important;
  outline: unset!important;

  &::placeholder {
    color: white!important;
  }

  @media ${device.tabletS} {
    font-size: 1.125rem!important;
  }
`

const Wrapper = styled.div`
  background-color: ${backgroundColor.dark.bg};
  color: ${backgroundColor.dark.text};

  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    padding: 0;
  }
`

const TitleWrapper = styled.div`
  padding-top: 40px;

  @media ${device.tabletS} {
    padding-top: 60px;
  }

  @media ${device.laptop} {
    grid-column-start: 2;
    grid-column-end: 2;
    padding-top: 90px;
  }
`

const StyledTitle = styled(Title)`
  margin: 0;
  padding: 0;

  @media (max-width: ${screenSize.mobileL - 1}px) {
    font-size: 1.25rem;
  }
`

const FormikWrapper = styled.div`
  grid-column-start: 2;
  grid-column-end: 2;
`

const LabelWrapper = styled.div`
  padding-bottom: 10px;

  @media ${device.tabletS} {
    padding-bottom: 25px;
  }
`

const ButtonWrapper = styled(LabelWrapper)`
  text-align: center;
  padding-bottom: 40px;
  padding-top: 20px;

  @media ${device.tablet} {
    padding-bottom: 75px;
    padding-top: 20px;
  }
`

const LabelTitle = styled.div`
  font-size: 0.875rem;
  font-family: Open Sans;
  display: block;
  width: 100%;
  padding-bottom: 10px;

  @media ${device.tabletS} {
    font-size: 1.125rem;
    padding-bottom: 25px;
  }
`

const StyledLabel = styled.label`
  font-family: Open Sans !important;
  font-size: 0.875rem !important;
  display: block;
  width: 100%;
  padding-bottom: 13px;

  @media ${device.tabletS} {
    font-size: 1.125rem !important;
  }
`

const StyledField = styled(Field)<{ error?: boolean }>`
  ${InputCss}

  ${(props) => props.error && `border-color: ${color.primary}!important;`}
  ${(props) =>
    props.error && `background-color: rgb(217 31 42 / 26%)!important;`}
`

const StyledTextarea = styled.textarea<{ error?: any }>`
  ${InputCss}
  resize: none;
  height: 150px;
  ${(props) => props.error && `border-color: ${color.primary}!important;`}
  ${(props) =>
    props.error && `background-color: rgb(217 31 42 / 26%)!important;`}
`

const CheckboxWrapper = styled.div`
  display: grid;
  width: 100%;
  gap: 15px;

  @media ${device.tablet} {
    display: inline-grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0px;
  }
`

const StyledCheckboxLabel = styled(StyledLabel)`
  display: inline-block;
  width: auto;
  padding-bottom: 0;
`

const StyledCheckboxField = styled(Field)`
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  width: 28px;
  height: 28px;
  vertical-align: middle;
  accent-color: #586d94;
  cursor: pointer;

  border-radius: unset;
  border-color: #ffffff4f;
  border-width: thin;
  border-style: revert;
`

const CheckboxText = styled.div`
  display: inline;
  box-sizing: border-box;
  font-size: 0.875rem;
  color: #ffffff;
  font-family: Open Sans;
  margin-left: 13px;
  text-align: justify;

  @media ${device.tabletS} {
    font-size: 1.125rem;
  }
`

const DayPickerWrapper = styled.div`
  position: absolute;
  background-color: white;
  color: black;
  z-index: 1;
  left: 0;

  @media (min-width: 332px) {
    left: unset;
  }
`

const StyledPhoneInput = styled(PhoneInput)<{ error?: any }>`
  & > input {
    width: 100% !important;
    ${InputCss};
    ${(props) => props.error && `border-color: ${color.primary}!important;`}
    ${(props) =>
      props.error && `background-color: rgb(217 31 42 / 26%)!important;`}
  }
  & > .flag-dropdown,
  & > .flag-dropdown > .selected-flag:hover,
  & > .flag-dropdown > .selected-flag > .country-list {
    color: white !important;
    background-color: rgb(255 255 255 / 26%) !important;
  }

  & > .flag-dropdown > .country-list {
    color: black !important;
  }
`

const StyledErrorMessage = styled.span`
  font-family: "Open Sans";
  color: ${color.primary};
  padding-left: 13px;
`

declare global {
  interface Window {
    gtag: any
  }
}

const ContactForm = ({
  title,
  type,
  button,
  agreementText,
  formName,
}: ContactFormInterface) => {
  const contactFormSection = useFormContext()

  const nameData: any =
    type === "person" ? formdata.personName : formdata.firmName
  const date = new Date()
  date.setDate(date.getDate() - 1)

  const initialValues: ValuesInterface = {
    name: "",
    email: "",
    phone: "",
    countryCode: "pl",
    text: "",
    serviceType: [],
    agreement: false,
    date: "",
  }

  const [selected, setSelected] = useState<Date>()
  const [isPopperOpen, setIsPopperOpen] = useState(false)

  const handleIsPopperOpen = () => {
    setIsPopperOpen(!isPopperOpen)
  }

  const closePopper = () => {
    setIsPopperOpen(false)
  }

  function encode(data: any) {
    return Object.keys(data)
      .map((key) => {
        if (key !== "countryCode" && key !== "agreement") {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        }
        return null
      })
      .join("&")
  }

  const onSubmit = (
    values: ValuesInterface,
    { setSubmitting, resetForm }: FormikHelpers<ValuesInterface>
  ) => {
    const smartformsUrl =
      "https://smartforms.dev/submit/629654b47a195017922e5cda"
    const nodejsUrl = "https://wojs-backend.vercel.app/send"

    fetch(nodejsUrl, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        formName,
        ...values,
      }),
    })
      .then(() => {
        window.gtag("event", "conversion", {
          send_to: "AW-10930055385/WvsDCMK21tADENnR7dso",
        })
        setSubmitting(false)
        resetForm()
        navigate("/success/")
      })
      .catch((error) => {
        console.log(error)
        fetch(smartformsUrl, {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            formName,
            ...values,
          }),
        })
          .then(() => {
            window.gtag("event", "conversion", {
              send_to: "AW-10930055385/WvsDCMK21tADENnR7dso",
            })
            setSubmitting(false)
            resetForm()
            navigate("/success/")
          })
          .catch((error) => console.log(error))
      })
  }

  return (
    <Wrapper>
      {contactFormSection && (
        <TitleWrapper ref={contactFormSection.sectionRef} id="contact-form">
          <StyledTitle level="h2">{title}</StyledTitle>
        </TitleWrapper>
      )}

      <FormikWrapper>
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setValues,
          }) => (
            <form method="post" name={formName} onSubmit={handleSubmit}>
              <LabelWrapper>
                {nameData.text ? (
                  <StyledLabel>
                    {nameData.text}
                    <StyledErrorMessage>
                      <ErrorMessage name="name" />
                    </StyledErrorMessage>
                  </StyledLabel>
                ) : (
                  <StyledErrorMessage>
                    <ErrorMessage name="name" />
                  </StyledErrorMessage>
                )}
                <StyledField
                  name="name"
                  type="text"
                  placeholder={nameData.placeholder}
                  error={errors.name && touched.name && errors.name}
                />
              </LabelWrapper>

              <LabelWrapper>
                {formdata.email.text ? (
                  <StyledLabel>
                    {formdata.email.text}
                    <StyledErrorMessage>
                      <ErrorMessage name="email" />
                    </StyledErrorMessage>
                  </StyledLabel>
                ) : (
                  <StyledErrorMessage>
                    <ErrorMessage name="email" />
                  </StyledErrorMessage>
                )}
                <StyledField
                  name="email"
                  type="email"
                  placeholder={formdata.email.placeholder}
                  error={errors.email && touched.email && errors.email}
                />
              </LabelWrapper>

              <LabelWrapper>
                {formdata.phone.text ? (
                  <StyledLabel>
                    {formdata.phone.text}
                    <StyledErrorMessage>
                      <ErrorMessage name="phone" />
                    </StyledErrorMessage>
                  </StyledLabel>
                ) : (
                  <StyledErrorMessage>
                    <ErrorMessage name="phone" />
                  </StyledErrorMessage>
                )}
                <StyledPhoneInput
                  inputProps={{
                    id: "phone",
                    name: "phone",
                    type: "tel",
                  }}
                  error={errors.phone && touched.phone && errors.phone}
                  country={"pl"}
                  value={values.phone}
                  regions={"europe"}
                  onChange={(phone, country: any) =>
                    setValues({
                      ...values,
                      phone: `+${phone}`,
                      countryCode: country.countryCode,
                    })
                  }
                  countryCodeEditable={false}
                />
              </LabelWrapper>

              <LabelWrapper>
                {formdata.text.text ? (
                  <StyledLabel htmlFor="text">
                    {formdata.text.text}
                    <StyledErrorMessage>
                      <ErrorMessage name="text" />
                    </StyledErrorMessage>
                  </StyledLabel>
                ) : (
                  <StyledErrorMessage>
                    <ErrorMessage name="text" />
                  </StyledErrorMessage>
                )}
                <Field
                  id="text"
                  name="text"
                  type="text"
                  placeholder={formdata.text.placeholder}
                  error={errors.text && touched.text && errors.text}
                >
                  {({ field, form, meta }: any) => {
                    return (
                      <StyledTextarea
                        id={field.name}
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        placeholder={formdata.text.placeholder}
                        error={errors.text && touched.text && errors.text}
                      />
                    )
                  }}
                </Field>
              </LabelWrapper>

              <LabelWrapper>
                {formdata.serviceType.text ? (
                  <LabelTitle id="checkbox-group">
                    {formdata.serviceType.text}
                    <StyledErrorMessage>
                      <ErrorMessage name="serviceType" />
                    </StyledErrorMessage>
                  </LabelTitle>
                ) : (
                  <StyledErrorMessage>
                    <ErrorMessage name="serviceType" />
                  </StyledErrorMessage>
                )}
                <CheckboxWrapper role="group" aria-labelledby="checkbox-group">
                  {formdata.serviceType.values.map((name, idx) => (
                    <StyledCheckboxLabel key={idx}>
                      <StyledCheckboxField
                        type="checkbox"
                        name="serviceType"
                        value={name}
                      />
                      <CheckboxText>{name}</CheckboxText>
                    </StyledCheckboxLabel>
                  ))}
                </CheckboxWrapper>
              </LabelWrapper>

              <LabelWrapper>
                <StyledField
                  type="text"
                  name="date"
                  value={values.date}
                  placeholder={formdata.date.placeholder}
                  onClick={handleIsPopperOpen}
                  onChange={() =>
                    setValues({
                      ...values,
                    })
                  }
                />
                {isPopperOpen && (
                  <DayPickerWrapper>
                    <DayPicker
                      initialFocus={isPopperOpen}
                      mode="single"
                      selected={selected}
                      disabled={{ from: new Date(0, 0, 0), to: date }}
                      // @ts-ignore
                      onSelect={(date: Date) => {
                        if (isValid(date)) {
                          setSelected(date)
                          setValues({
                            ...values,
                            date: format(date, "y-MM-dd"),
                          })
                        }
                        closePopper()
                      }}
                    />
                  </DayPickerWrapper>
                )}
              </LabelWrapper>

              <LabelWrapper>
                <StyledCheckboxLabel>
                  <StyledCheckboxField type="checkbox" name="agreement" />
                  <CheckboxText>
                    {agreementText || formdata.agreement.text}
                  </CheckboxText>

                  <StyledErrorMessage>
                    <ErrorMessage name="agreement" />
                  </StyledErrorMessage>
                </StyledCheckboxLabel>
              </LabelWrapper>

              <ButtonWrapper>
                <Button type="submit" disabled={isSubmitting}>
                  {button ? button.title : formdata.button.title}
                </Button>
              </ButtonWrapper>
            </form>
          )}
        </Formik>
      </FormikWrapper>
    </Wrapper>
  )
}

export default ContactForm
