import * as React from "react"
import styled from "styled-components"
import { device, getBackgroundColor } from "../../GlobalStyles"
import Title from "../../shared/Title/Title"
import { TextInterface } from "./text.interface"

const Color = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
  width: 100%;
`

const Wrapper = styled.div`
  padding: 15px 20px 30px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;

  @media ${device.laptop} {
    max-width: 870px;
    width: 100%;
    margin: 0 auto;
    padding: 70px 20px 85px 20px;
  }
`

const StyledTitle = styled(Title)`
  padding: 0;
  margin: 0;
`

const StyledText = styled.p`
  padding: 0;
  padding-top: 20px;
  margin: 0;
  font-size: 0.875rem;

  @media ${device.laptop} {
    font-size: 1.125rem;
  }
`

const Text = ({ color, title, text }: TextInterface) => {
  return (
    <Color color={getBackgroundColor(color)}>
      <Wrapper>
        <StyledTitle level={"h4"}>{title}</StyledTitle>
        <StyledText>{text}</StyledText>
      </Wrapper>
    </Color>
  )
}

export default Text
