import * as React from "react"
import styled from "styled-components"
import { color, device, getBackgroundColor } from "../../GlobalStyles"
import Button from "../../shared/Button/Button"
import Icon from "../../shared/Icon/Icon"
import { OfferCardsInterface } from "./offerCards.interface"

const Color = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
  width: 100%;
  text-align: center;
`

const OfferCardsWrapper = styled.div`
  margin: 0px auto;
  padding: 10px 0;
  text-align: center;
  width: 100%;
  max-width: 1920px;
  box-sizing: border-box;

  @media ${device.laptop} {
    padding: 60px 0 65px 0;
    width: 100%;
  }

  @media ${device.desktop} {
    padding: 70px 0 80px 0;
  }
`

const IconWrapper = styled.div`
  padding: 20px 0 25px;

  @media ${device.laptop} {
    padding: 70px 0 30px;
  }
`

const OfferCardsSquare = styled.div`
  background-color: ${color.decorationDark};
  max-width: 340px;
  width: 100%;
  margin: 10px auto;
  display: inline-block;

  @media ${device.mobileL} {
    max-width: 400px;
  }

  @media ${device.tablet} {
    max-width: 480px;
  }

  @media ${device.laptop} {
    max-width: 290px;
    margin: 0px 10px;
  }

  @media ${device.laptopL} {
    max-width: 360px;
  }

  @media ${device.desktop} {
    max-width: 400px;
  }
`

const OfferCardsText1 = styled.p`
  color: ${color.veryLight};
  font: bold 1.125rem Montserrat;
  margin: 0px auto;
  text-align: center;
  letter-spacing: 1px;

  @media ${device.laptop} {
    margin: 0px auto;
    position: static;
  }

  @media ${device.laptopL} {
    margin: 0px auto;
  }

  @media ${device.desktop} {
    font: bold 1.25rem Montserrat;
    margin: 0px auto;
  }
`
const OfferCardsText2 = styled.p`
  color: ${color.veryLight};
  font: bold 1.125rem Montserrat;
  margin: 0px auto 15px auto;
  text-align: center;
  letter-spacing: 1px;

  @media ${device.laptop} {
    margin: 0px auto 30px auto;
    position: static;
  }

  @media ${device.laptopL} {
    margin: 0px auto 40px auto;
  }

  @media ${device.desktop} {
    font: bold 1.25rem Montserrat;
    margin: 0px auto 60px auto;
  }
`
const OfferCardsButton = styled.div`
  padding-bottom: 15px;

  @media ${device.laptop} {
    padding-bottom: 60px;
  }
`

const OfferCards = ({ color, cards }: OfferCardsInterface) => {
  return (
    <Color color={getBackgroundColor(color)}>
      <OfferCardsWrapper>
        {cards.map(({ icon, title, button }, idx: React.Key) => (
          <OfferCardsSquare key={idx}>
            <IconWrapper>
              <Icon type={icon} />
            </IconWrapper>
            <OfferCardsText1>{title.text1}</OfferCardsText1>
            <OfferCardsText2>{title.text2}</OfferCardsText2>
            <OfferCardsButton>
              <Button {...button}>{button.title}</Button>
            </OfferCardsButton>
          </OfferCardsSquare>
        ))}
      </OfferCardsWrapper>
    </Color>
  )
}

export default OfferCards
