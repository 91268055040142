import * as React from "react"
import styled from "styled-components"
import { color, device, getBackgroundColor } from "../../GlobalStyles"
import Title from "../../shared/Title/Title"
import Button from "../../shared/Button/Button"
import { ReferenceInterface } from "./reference.interface"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import LinkTo from "../../shared/Link/LinkTo"

const Color = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
  width: 100%;
  text-align: center;
  padding: 40px 0 40px 0;

  @media ${device.laptop} {
    padding: 60px 0 65px 0;
  }
`

const Wrapper = styled.div`
  position: relative;
  max-width: 1240px;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  width: 90%;

  @media ${device.laptop} {
    border-top: 1px solid ${color.decorationDark};
    padding-top: 70px;
  }
`
const RefTitle = styled(Title)`
  font-size: 1.125rem;
  color: ${color.default};
  margin: 0px auto 0px auto;
  padding-bottom: 35px;

  @media ${device.tablet} {
    font-size: 1.5rem;
  }

  @media ${device.laptop} {
    font-size: 1.75rem;
    margin: 0px auto 80px auto;
    padding-bottom: 0px;
  }
`

const RefText = styled.p<{ small?: boolean }>`
  font-family: Open Sans;
  font-size: 0.875rem;
  color: ${color.default};
  padding: 30px;
  margin: 0px auto 0px auto;
  width: 84%;
  text-align: center;
  letter-spacing: 0.022rem;

  @media ${device.tablet} {
    font-size: 1.125rem;
    max-width: ${(props) => (props.small ? "710px" : "unset")};
  }

  @media ${device.laptop} {
    font-size: 1.25rem;
    padding: 0px;
    border-top: none;
  }
`

const RefName = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 0.875rem;
  color: ${color.decorationDark};
  margin: 10px auto 30px auto;

  @media ${device.tablet} {
    font-size: 1.125rem;
  }

  @media ${device.laptop} {
    font-size: 1.25rem;
    margin: 20px auto 80px auto;
  }
`

const Line = styled.div`
  position: relative;
  margin: 0px auto 0px auto;
  border-top: 1px solid ${color.decorationDark};
  width: 84%;
  text-align: center;

  @media ${device.laptop} {
    border-top: none;
  }
`

const Image = styled.div`
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
  padding-top: 20px;

  @media ${device.laptop} {
    max-width: 200px;
    padding-bottom: 20px;
    padding-top: 0px;
  }
`

const StaticImageWrapper = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    position: absolute;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    top: -10px;
  }
`

const StaticImageWrapper2 = styled.div`
  position: absolute;
  width: 20px;
  top: -5px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);

  @media ${device.tablet} {
    width: 30px;
    top: -7px;
  }

  @media ${device.laptop} {
    display: none;
    width: 40px;
    top: -80px;
  }
`

const Reference = ({
  title,
  text,
  author,
  button,
  link,
  color,
  logo,
  small,
  className,
}: ReferenceInterface) => {
  const img = logo && getImage(logo.image)

  return (
    <Color color={getBackgroundColor(color)} className={className}>
      <Wrapper>
        <StaticImageWrapper>
          <StaticImage
            src="../../../images/quote.png"
            alt="quote"
            style={{
              width: "100% ",
            }}
            placeholder="none"
            quality={100}
            objectFit="contain"
          />
        </StaticImageWrapper>
        {title && <RefTitle level="h2">{title}</RefTitle>}
        <Line>
          <StaticImageWrapper2>
            <StaticImage
              src="../../../images/quote.png"
              alt="quote"
              style={{
                width: "100% ",
              }}
              placeholder="none"
              quality={100}
              objectFit="contain"
            />
          </StaticImageWrapper2>
        </Line>
        {img && (
          <Image>
            <GatsbyImage image={img} alt={logo.alt} />
          </Image>
        )}
        <RefText small={small}>{text}</RefText>
        <RefName>{author}</RefName>
        {button && <Button {...button}>{button.title}</Button>}
        {link && (
          <LinkTo href={link.pdfURL.publicURL} target="_blank" rel="noreferrer">
            {link.title}
          </LinkTo>
        )}
      </Wrapper>
    </Color>
  )
}

export default Reference
