import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"
import {
  color,
  device,
  getBackgroundColor,
  screenSize,
} from "../../GlobalStyles"
import Button from "../../shared/Button/Button"
import Icon from "../../shared/Icon/Icon"
import LinkTo from "../../shared/Link/LinkTo"
import Title from "../../shared/Title/Title"
import { InfoInterface } from "./info.interface"

const Color = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
  width: 100%;
`

const Wrapper = styled.div`
  width: 100%;
  padding: 0px 0px 40px 0px;
  box-sizing: border-box;

  @media ${device.tablet} {
    display: grid;
    row-gap: 80px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 120px 20px 80px 20px;
  }
`

const Information = styled.div<{ position?: any; type?: any }>`
  display: grid;
  row-gap: 20px;
  padding: 20px 20px 50px 20px;
  max-width: 376px;
  margin: 0 auto;

  @media ${device.tablet} {
    padding: 0;
    margin: 0;
    grid-row-start: 1;
    grid-column-start: ${(props) => (props.position === "right" ? 1 : 2)};
    max-width: 500px;
    align-content: center;
    align-items: center;
    text-align: ${(props) => (props.position === "right" ? "right" : "left")};

    ${(props) =>
      props.type === "center" &&
      `
      text-align: left;
      ${ContactText}{
        @media ${device.laptop} {
          margin-top: 15px;
          margin-bottom: 30px;
        }
      }
    `};
  }
`

const InfoWrapper = styled.div<{ position?: any; type?: any }>`
  display: grid;
  justify-items: start;

  :last-child {
    ${Information} {
      padding-bottom: 0;
    }
  }

  @media ${device.tablet} {
    gap: 40px;
    grid-template-columns: ${(props) =>
      props.position === "right" ? "auto 300px" : "300px auto"};
    justify-items: ${(props) => (props.position === "right" ? "end" : "start")};

    ${(props) => props.type === "center" && "grid-template-columns: 1fr 1fr;"};
  }

  @media ${device.laptop} {
    grid-template-columns: ${(props) =>
      props.position === "right" ? "auto 380px" : "380px auto"};

    ${(props) => props.type === "center" && "grid-template-columns: 1fr 1fr;"};
  }
`

const Image = styled.div<{ position?: any }>`
  width: 100%;
  height: auto;
  text-align: center;

  @media ${device.tablet} {
    grid-row-start: 1;
    grid-column-start: ${(props) => (props.position === "right" ? 2 : 1)};
    text-align: unset;
  }

  @media screen and (max-width: ${screenSize.mobileL - 1}px) {
    .art-directed {
      width: 100%;
      max-width: ${screenSize.mobileL - 1}px;
      aspect-ratio: auto 360 / 196;
    }
  }
`

const StyledTitle = styled(Title)<{ type?: any }>`
  display: ${(props) => (props.type === "center" ? "none" : "block")};
  color: ${color.decorationDark};
  margin: 0;
  padding: 0;
  white-space: pre-line;

  @media ${device.tablet} {
    display: block;
  }
`

const StyledTitle2 = styled(Title)<{ type?: any }>`
  display: ${(props) => (props.type === "center" ? "block" : "none")};
  color: ${color.decorationDark};
  padding: 40px 0 20px 0;
  margin: 0 auto;
  white-space: pre-line;

  @media ${device.tablet} {
    display: none;
  }
`

const Text = styled.div`
  font-size: 0.875rem;
  margin: 0;
  padding: 0;

  @media ${device.tablet} {
    font-size: 1.05rem;
  }

  @media ${device.laptop} {
    font-size: 1.125rem;
  }
`

const Subtitle = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  margin: 0;
  padding: 0;

  @media ${device.tablet} {
    font-size: 1.2rem;
  }

  @media ${device.laptop} {
    font-size: 1.25rem;
  }
`

const StyledLinkTo = styled(LinkTo)`
  font-size: 0.875rem;
  margin: 0;
  padding: 0;
  text-align: center;

  @media ${device.tablet} {
    font-size: 1.05rem;
    text-align: unset;
  }

  @media ${device.laptop} {
    font-size: 1.125rem;
    argin-top: 10px;
  }
`

const ContactText = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-align: center;

  @media ${device.tablet} {
    font-size: 1.1rem;
    margin-top: 15px;
    text-align: unset;
  }

  @media ${device.laptop} {
    font-size: 1.375rem;
    margin-top: 30px;
  }
`

const ContactPhone = styled.div`
  display: none;

  @media ${device.mobileL} {
    display: block;
    text-align: center;
    font-size: 0.875rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }

  @media ${device.tablet} {
    font-size: 1.1rem;
    text-align: unset;
  }

  @media ${device.laptop} {
    font-size: 1.25rem;
  }
`

const ButtonWrapper = styled.div`
  text-align: center;

  @media ${device.mobileL} {
    display: none;
  }
`

const StyledIcon = styled(Icon)`
  padding-right: 15px;
`

const Info = ({ color, info, type }: InfoInterface) => {
  return (
    <Color color={getBackgroundColor(color)}>
      <Wrapper>
        {info.map(
          ({ title, text, subtitle, image, link, contact }, idx: React.Key) => {
            const img = withArtDirection(getImage(image.image)!, [
              {
                media: `(max-width: ${screenSize.mobileL - 1}px)`,
                image: getImage(image.imageMobile)!,
              },
            ])
            return (
              <InfoWrapper key={idx} position={image.position} type={type}>
                {title && (
                  <StyledTitle2 level={"h4"} type={type}>
                    {title}
                  </StyledTitle2>
                )}
                {img && (
                  <Image position={image.position}>
                    <GatsbyImage
                      image={img}
                      alt={image.alt}
                      className="art-directed"
                    />
                  </Image>
                )}
                <Information position={image.position} type={type}>
                  {title && (
                    <StyledTitle level={"h4"} type={type}>
                      {title}
                    </StyledTitle>
                  )}
                  {text && <Text>{text}</Text>}
                  {subtitle && <Subtitle>{subtitle}</Subtitle>}
                  {link && (
                    <StyledLinkTo to={link.to}>{link.title}</StyledLinkTo>
                  )}
                  {contact && (
                    <>
                      {contact.text && (
                        <ContactText>{contact.text}</ContactText>
                      )}
                      {contact.icon && (
                        <ContactPhone>
                          <StyledIcon type={contact.icon} />
                          {contact.tel}
                        </ContactPhone>
                      )}
                      {contact.button && (
                        <ButtonWrapper>
                          <Button {...contact.button}>
                            {contact.button.title}
                          </Button>
                        </ButtonWrapper>
                      )}
                    </>
                  )}
                </Information>
              </InfoWrapper>
            )
          }
        )}
      </Wrapper>
    </Color>
  )
}

export default Info
