import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "../../shared/Button/Button"
import { ImageButtonsInterface } from "./imageButtons.interface"
import styled from "styled-components"
import { device, getBackgroundColor } from "../../GlobalStyles"
import Title from "../../shared/Title/Title"
import { Link } from "gatsby"

const Color = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
  width: 100%;

  a {
    color: ${(props) => props.color.text};
  }
`

const Wrapper = styled.div`
  padding: 40px 0 40px 0;
  width: 100%;
  text-align: center;

  @media ${device.laptop} {
    display: block;
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    padding: 80px 0 100px 0;
  }
`

const H2 = styled(Title)`
  padding-bottom: 20px;
  margin: 0;
  text-align: center;

  @media ${device.laptop} {
    padding-bottom: 50px;
  }
`
const ImagesWrapper = styled.div`
  width: 100%;
  padding: 0 5%;
  box-sizing: border-box;

  @media ${device.laptop} {
    display: inline-grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding: 0 1%;
  }
`

const Image = styled.div`
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 1;
  transition: transform 0.2s;
`

const StyledLink = styled(Link)`
  max-width: 320px;
  text-decoration: none;
  display: grid;
  text-align: center;
  margin: 15px auto;
  overflow: hidden;
  grid-template-rows: 5fr 3fr;

  @media ${device.mobileM} {
    grid-template-rows: 2fr 1fr;
  }

  @media ${device.laptop} {
    max-width: 400px;
    grid-template-rows: 2fr 1fr;
    margin: 0;
  }

  :hover {
    ${Image} {
      transform: scale(1.2);
    }
  }
`

const Data = styled.div`
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 1;
  z-index: 1;
  background-color: rgb(255 255 255 / 72%);
`

const Text = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 1.125rem;
  padding: 30px 0 15px 0;

  @media ${device.laptop} {
    padding: 30px 0 20px 0;
    font-size: 1.5rem;
  }

  @media ${device.laptopL} {
    font-size: 1.6rem;
  }

  @media ${device.desktop} {
    font-size: 1.75rem;
  }
`

const ImageButtons = ({ title, imgButtons, color }: ImageButtonsInterface) => {
  return (
    <Color color={getBackgroundColor(color)}>
      <Wrapper>
        <H2 level="h2">{title}</H2>
        <ImagesWrapper>
          {imgButtons.map(({ title, button, image, to }, idx: React.Key) => {
            const img = getImage(image.image)
            return (
              <StyledLink key={idx} to={to}>
                <Image>
                  {img && <GatsbyImage image={img} alt={image.alt} />}
                </Image>
                <Data>
                  <Text>{title}</Text>
                  <div>
                    <Button {...button}>{button.title}</Button>
                  </div>
                </Data>
              </StyledLink>
            )
          })}
        </ImagesWrapper>
      </Wrapper>
    </Color>
  )
}

export default ImageButtons
