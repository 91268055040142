import * as React from "react"
import { PrivacyPolicyInterface } from "./privacyPolicy.interface"
import Hero from "../sections/Hero/Hero2"
import { MDXRenderer } from "gatsby-plugin-mdx"
import styled from "styled-components"
import { color, device } from "../GlobalStyles"

const Wrapper = styled.div`
  font-family: Open Sans, serif;
  font-size: 0.875rem;
  margin: 0px auto;
  padding: 40px 20px;
  width: 100%;
  max-width: 750px;
  box-sizing: border-box;

  @media ${device.laptop} {
    font-size: 1.125rem;
    padding: 70px 0;
    width: 100%;
  }

  h3 {
    margin: 30px 0 15px 0;
  }

  h3:first-child {
    margin-top: 0;
  }

  p {
    margin: 0;
  }

  li {
    margin: 5px 0;
  }

  a {
    color: ${color.primary};
    text-decoration: none;
  }

  a:hover {
    color: ${color.primaryHover};
    text-decoration: none;
  }
`

const PrivacyPolicy = ({
  body,
  privacyPolicy: { hero },
}: {
  privacyPolicy: PrivacyPolicyInterface
  body: string
}) => {
  return (
    <>
      {hero.show && <Hero {...hero} />}
      <Wrapper>
        <MDXRenderer>{body}</MDXRenderer>
      </Wrapper>
    </>
  )
}

export default PrivacyPolicy
