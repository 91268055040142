import * as React from "react"
import styled from "styled-components"
import { Hero2Interface as HeroInterface } from "./hero.interface"
import Title from "../../shared/Title/Title"
import { color, device } from "../../GlobalStyles"

const Wrapper = styled.div`
  background-color: ${color.light};
`

const H1 = styled(Title)`
  color: ${color.darkText};
  padding: 35px 0 40px 0;
  margin: 0;
  text-align: center;

  @media ${device.laptop} {
    padding: 80px 0 60px 0;
  }
`

const Hero = ({ title }: HeroInterface) => {
  return (
    <Wrapper>
      <H1 level="h1">{title}</H1>
    </Wrapper>
  )
}

export default Hero
