import * as React from "react"
import { ContactInterface } from "./contact.interface"
import ContactForm from "../sections/Form/contactForm"
import Hero from "../sections/Hero/Hero2"
import ContactS from "../sections/Contact/Contact"

const Contact = ({
  contact: { hero, contactS, formS },
}: {
  contact: ContactInterface
}) => {
  return (
    <>
      {hero.show && <Hero {...hero} />}
      {contactS.show && <ContactS {...contactS} />}
      {formS.show && <ContactForm {...formS} />}
    </>
  )
}

export default Contact
