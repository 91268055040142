import * as React from "react"
import { PriceInterface } from "./price.interface"
import ContactForm from "../sections/Form/contactForm"
import Info from "../sections/Info/Info"
import PriceFactor from "../sections/PriceFactor/PriceFactor"
import Package from "../sections/Package/Package"
import Hero from "../sections/Hero/Hero"

const Price = ({
  price: { hero, packageS, priceFactorS, infoS, formS },
}: {
  price: PriceInterface
}) => {
  return (
    <>
      {hero.show && <Hero {...hero} />}
      {packageS.show && <Package {...packageS} />}
      {priceFactorS.show && <PriceFactor {...priceFactorS} />}
      {infoS.show && <Info {...infoS} />}
      {formS.show && <ContactForm {...formS} />}
    </>
  )
}

export default Price
