import * as React from "react"
import ContactForm from "../../sections/Form/contactForm"
import Hero from "../../sections/Hero/Hero"
import Info from "../../sections/Info/Info"
import Text from "../../sections/Text/Text"
import SeeMore from "../../sections/SeeMore/SeeMore"
import { TransportInterface } from "./transport.interface"

const Transport = ({
  businessOfferTransport: { hero, infoS, textS, seeMoreS, formS },
}: {
  businessOfferTransport: TransportInterface
}) => {
  return (
    <>
      {hero.show && <Hero {...hero} />}
      {infoS.show && <Info {...infoS} />}
      {textS.show && <Text {...textS} />}
      {seeMoreS.show && <SeeMore {...seeMoreS} />}
      {formS.show && <ContactForm {...formS} />}
    </>
  )
}

export default Transport
