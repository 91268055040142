import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"
import Button from "../../shared/Button/Button"
import { HeroInterface } from "./hero.interface"
import Title from "../../shared/Title/Title"
import { color, device, screenSize } from "../../GlobalStyles"
import { buttonAction } from "../../../utils/buttonAction"
import { useFormContext } from "../../../store/contact-form-ref-context"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;

  @media ${device.laptopM} {
    display: grid;
    grid-template-columns: repeat(22, 1fr);
    grid-template-rows: 1fr 4fr 1fr;
  }
`

const Image = styled.div`
  aspect-ratio: auto 968 / 377;
  grid-row-start: 1;
  grid-column-start: 1;

  @media ${device.laptopM} {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 23;
  }

  .art-directed {
    @media screen and (max-width: ${screenSize.tabletS - 1}px) {
      max-width: ${screenSize.tabletS - 1}px;
      aspect-ratio: auto 1 / 1;
    }

    @media screen and (max-width: ${screenSize.mobileL - 1}px) {
      max-width: ${screenSize.mobileL - 1}px;
      aspect-ratio: auto 3 / 4;
    }

    @media screen and (max-width: ${screenSize.mobileM - 1}px) {
      max-width: ${screenSize.mobileM - 1}px;
      aspect-ratio: auto 2 / 3;
    }
  }
`

const GridGap = styled.div`
  position: absolute;
  grid-row-start: 1;
  grid-column-start: 1;
  max-width: 90%;
  z-index: 1;
  background: rgb(255 255 255 / 61%);
  align-self: end;

  @media ${device.mobileM} {
    max-width: 85%;
  }

  @media ${device.tabletS} {
    max-width: 100%;
    align-self: auto;
  }

  @media ${device.tablet} {
    max-width: 90%;
    align-self: end;
  }

  @media ${device.laptopM} {
    position: relative;
    max-width: 100%;
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 4;
    grid-column-end: 15;
    align-self: auto;
    display: grid;
    z-index: 1;
  }
`

const TextWrapper = styled.div`
  margin-right: 20px;
  margin-left: 20px;

  @media ${device.laptopM} {
    grid-row-start: 2;
    grid-row-end: 3;

    margin-right: 30px;
    margin-left: 30px;
  }

  @media ${device.laptopL} {
    margin-right: 50px;
    margin-left: 50px;
  }

  @media ${device.desktop} {
    margin-right: 70px;
    margin-left: 70px;
  }
`

const H1 = styled(Title)`
  color: ${color.darkText};
  grid-column-start: 2;
  grid-column-end: 3;
  margin-top: 35px;
  z-index: 1;
`

const P = styled.p`
  font-family: Open Sans;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  font-weight: regular;
  color: ${color.darkText};
  width: 80%;
  margin: 20px 0;
  z-index: 1;

  @media ${device.laptopM} {
    width: 60%;
    font-size: 1.313rem;
    letter-spacing: 0.075rem;
  }

  @media ${device.laptopL} {
    font-size: 1.313rem;
  }

  @media ${device.desktop} {
    font-size: 1.5rem;
  }
`

const ButtonPosition = styled.div`
  display: grid;
  justify-items: start;

  @media ${device.tabletS} {
    display: block;
    grid-column-start: 2;
    grid-column-end: 3;
    margin-bottom: 10px;
    margin-top: 60px;
    z-index: 1;
    margin-bottom: 10px;
    margin-top: 40px;
  }

  @media ${device.laptopL} {
    margin-bottom: 10px;
    margin-top: 60px;
  }
`

const RedButton = styled(Button)`
  margin-bottom: 10px;

  @media ${device.tabletS} {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  @media ${device.laptopM} {
    margin-bottom: 0px;
  }
`

const BlueButton = styled(Button)`
  margin-bottom: 10px;

  @media ${device.tabletS} {
    margin-bottom: 10px;
  }

  @media ${device.laptopM} {
    margin-bottom: 0px;
  }
`

const HomePageHero = ({
  title,
  subtitle,
  primaryBtn,
  secondaryBtn,
  image,
}: HeroInterface) => {
  var heroImage = null
  if (image) {
    const imagePc = getImage(image.image)
    const imageTablet = image.imageTablet
      ? getImage(image.imageTablet)
      : imagePc

    heroImage = withArtDirection(imagePc!, [
      {
        media: `(max-width: ${screenSize.tabletS - 1}px)`,
        image: imageTablet!,
      },
    ])
  }

  const contactFormSection = useFormContext()

  return (
    <Wrapper>
      <Image>
        {heroImage && (
          <GatsbyImage
            image={heroImage}
            alt={image!.alt}
            className="art-directed"
          />
        )}
      </Image>
      <GridGap>
        <TextWrapper>
          <H1 level="h1">{title}</H1>
          {subtitle && <P>{subtitle}</P>}
          {(primaryBtn || secondaryBtn) && (
            <ButtonPosition>
              {primaryBtn && contactFormSection && (
                <RedButton
                  {...primaryBtn}
                  onClick={buttonAction(
                    contactFormSection.sectionRef,
                    primaryBtn.action
                  )}
                >
                  {primaryBtn.title || ""}
                </RedButton>
              )}
              {secondaryBtn && contactFormSection && (
                <BlueButton
                  {...secondaryBtn}
                  onClick={buttonAction(
                    contactFormSection.sectionRef,
                    secondaryBtn.action
                  )}
                >
                  {secondaryBtn.title || ""}
                </BlueButton>
              )}
            </ButtonPosition>
          )}
        </TextWrapper>
      </GridGap>
    </Wrapper>
  )
}

export default HomePageHero
