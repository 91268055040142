import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"
import Button from "../../shared/Button/Button"
import { HeroInterface } from "./hero.interface"
import Title from "../../shared/Title/Title"
import { color, device, screenSize } from "../../GlobalStyles"
import { buttonAction } from "../../../utils/buttonAction"
import { useFormContext } from "../../../store/contact-form-ref-context"

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const Image = styled.div`
  @media ${device.laptop} {
    height: 440px;
  }

  .gatsby-image-wrapper {
    height: 100%;
  }

  @media screen and (max-width: ${screenSize.laptop - 1}px) {
    .art-directed {
      max-width: ${screenSize.laptop - 1}px;
      height: 380px;
    }
  }
`

const TextBackground = styled.div<{ isButton?: any }>`
  position: absolute;
  background: rgb(255 255 255 / 75%);
  top: 0;
  width: 100%;
  max-width: 320px;
  padding: 30px 60px 30px 20px;
  left: 0;
  box-sizing: border-box;

  @media ${device.mobileL} {
    max-width: unset;
    width: 80%;
    padding: 20px;
  }

  @media ${device.tabletS} {
    max-width: unset;
    width: 80%;
    padding: 70px;
  }

  @media ${device.laptop} {
    max-width: 785px;
    padding: 70px;
    padding-bottom: ${(props) => (props.isButton ? "30px" : "70px")};
    left: 10%;
  }
`

const H1 = styled(Title)`
  color: ${color.darkText};
  padding: 0;
  margin: 0;
`

const Subtitle = styled.div`
  font-family: Open Sans;
  font-size: 0.875rem;
  letter-spacing: 0.05rem;
  font-weight: regular;
  color: ${color.darkText};
  padding-top: 20px;

  @media ${device.tabletS} {
    font-size: 1rem;
  }

  @media ${device.tablet} {
    font-size: 1.313rem;
    letter-spacing: 0.075rem;
  }

  @media ${device.laptopL} {
    font-size: 1.313rem;
  }

  @media ${device.desktop} {
    font-size: 1.5rem;
  }
`

const StyledButton = styled.div`
  margin-bottom: 20px;
  display: block;

  @media ${device.tablet} {
    display: inline-block;
    margin-bottom: 0px;
    margin-top: 40px;
  }
`

const StyledButtonLeft = styled(StyledButton)`
  margin-top: 20px;
  @media ${device.tablet} {
    margin-right: 30px;
  }
`

const StyledButtonRight = styled(StyledButton)``

const Hero = ({
  title,
  subtitle,
  primaryBtn,
  secondaryBtn,
  image,
}: HeroInterface) => {
  var heroImage = null
  if (image) {
    const imagePc = getImage(image.image)
    const imageTablet = image.imageTablet
      ? getImage(image.imageTablet)
      : imagePc

    heroImage = withArtDirection(imagePc!, [
      {
        media: `(max-width: ${screenSize.laptop - 1}px)`,
        image: imageTablet!,
      },
    ])
  }

  const isButton = primaryBtn || secondaryBtn

  const contactFormSection = useFormContext()

  return (
    <Wrapper>
      <Image>
        {heroImage && (
          <GatsbyImage
            objectFit={"cover"}
            image={heroImage}
            alt={image!.alt}
            className="art-directed"
          />
        )}
      </Image>
      <TextBackground isButton={isButton}>
        <H1 level="h1">{title}</H1>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}

        {primaryBtn && contactFormSection && (
          <StyledButtonLeft>
            <Button
              {...primaryBtn}
              onClick={buttonAction(
                contactFormSection.sectionRef,
                primaryBtn.action
              )}
            >
              {primaryBtn.title || ""}
            </Button>
          </StyledButtonLeft>
        )}

        {secondaryBtn && contactFormSection && (
          <StyledButtonRight>
            <Button
              {...secondaryBtn}
              onClick={buttonAction(
                contactFormSection.sectionRef,
                secondaryBtn.action
              )}
            >
              {secondaryBtn.title || ""}
            </Button>
          </StyledButtonRight>
        )}
      </TextBackground>
    </Wrapper>
  )
}

export default Hero
