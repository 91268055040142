import * as React from "react"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { ContactInterface } from "./contact.interface"
import styled from "styled-components"
import { color, device, getBackgroundColor } from "../../GlobalStyles"
import Title from "../../shared/Title/Title"
import Icon from "../../shared/Icon/Icon"
import LinkTo from "../../shared/Link/LinkTo"

const Color = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
  width: 100%;

  @media ${device.laptop} {
    padding: 0 20px;
    box-sizing: border-box;
  }
`

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  box-sizing: border-box;
  margin: 40px auto 0;
  background-color: ${color.light};
  max-width: 425px;

  @media ${device.laptop} {
    max-width: 1284px;
    margin: 100px auto 70px auto;
    grid-template-columns: 1fr 2fr;
    background-color: ${color.veryLight2};
  }

  .logo {
    width: 190px;
    height: auto;
    right: 0;
    left: unset;
    padding-right: 15px;
  }
`

const ImageMobile = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -1;

  @media ${device.laptop} {
    display: none;
  }

  .art-directed {
    width: 100%;
    height: 326px;
  }
`

const ImageDesktop = styled.div`
  display: none;

  @media ${device.laptop} {
    width: 100%;
    display: block;

    grid-column-start: 1;
    grid-row-start: 1;
  }
`

const StaticImageMobile = styled.div`
  @media ${device.laptop} {
    display: none;
  }
`

const StaticImageDesktop = styled.div`
  display: none;

  @media ${device.laptop} {
    display: block;
    position: relative;
    grid-column-start: 1;
    grid-row-start: 1;
    align-self: end;
    left: 60px;
    padding-bottom: 25px;
  }
`

const Grid = styled.div`
  @media ${device.laptop} {
    display: grid;
    grid-template-columns: auto auto;
    align-content: center;
    align-items: baseline;

    .phone-0 {
      padding-top: 90px;
    }
  }

  @media ${device.laptopM} {
    .phone-0 {
      padding-top: 125px;
    }
  }
`

const TitleWrapper = styled.div`
  padding: 0 20px;
  background-color: #ffffff;

  @media ${device.laptop} {
    background-color: ${color.veryLight2};
    grid-column-start: 1;
    grid-column-end: 3;
    padding-left: 100px;
  }

  @media ${device.laptopM} {
    padding-left: 140px;
  }
`

const StyledTitle = styled(Title)`
  padding: 0 0 10px;
  margin: 0;

  @media ${device.laptop} {
    padding-bottom: 30px;
  }
`

const StyledSubtitle = styled.div`
  font-size: 1rem;
  margin: 0;
  padding: 0;

  @media ${device.laptop} {
    font-size: 1.5rem;
  }
`

const Mail = styled.div`
  display: grid;
  padding: 40px 20px 20px;
  grid-template-columns: auto auto;
  align-items: center;
  justify-items: start;
  justify-content: start;
  column-gap: 15px;
  background-color: #ffffff;

  @media ${device.mobileM} {
    column-gap: 20px;
  }

  @media ${device.laptop} {
    background-color: ${color.veryLight2};
    grid-column-start: 1;
    grid-column-end: 3;
    padding-left: 100px;
  }

  @media ${device.laptopM} {
    padding-left: 140px;
  }
`

const Data = styled.div`
  font-size: 0.95rem;
  font-weight: 600;
  color: ${color.decorationDark};
  white-space: nowrap;

  @media ${device.laptop} {
    font-size: 1.25rem;
  }
`

const StyledLinkTo = styled(LinkTo)`
  font-size: 0.95rem;
  font-weight: 600;
  color: ${color.decorationDark};
  white-space: nowrap;

  @media ${device.laptop} {
    font-size: 1.25rem;
  }
`

const Phone = styled.div`
  display: grid;
  padding: 20px 20px;
  grid-template-columns: auto auto;
  align-items: center;
  justify-items: start;
  justify-content: start;
  column-gap: 15px;
  row-gap: 10px;
  background-color: #ffffff;

  @media ${device.mobileM} {
    column-gap: 20px;
  }

  @media ${device.laptop} {
    background-color: ${color.veryLight2};
    grid-column-start: 1;
    padding-left: 100px;
  }

  @media ${device.laptopM} {
    padding-left: 220px;
    padding-left: 140px;
  }
`

const DataTitle = styled(Data)`
  color: ${color.default};

  @media ${device.laptop} {
    font-size: 1rem;
  }
`

const PhoneTitle = styled(DataTitle)`
  grid-column-start: 1;
  grid-column-end: 3;
`

const Address = styled.div`
  position: relative;
  padding: 0 20px;
  z-index: 1;

  @media ${device.laptop} {
    grid-column-start: 2;
    grid-row-start: 3;
    grid-row-end: 5;
    padding-top: 90px;

    .nip {
      padding-top: 35px;
      padding-bottom: 35px;
    }
  }

  @media ${device.laptopM} {
    padding-top: 125px;
  }
`

const AddressTitle = styled(DataTitle)`
  padding: 15px 0;

  @media ${device.laptop} {
    padding-top: 0;
  }
`

const AddressData = styled(Data)`
  font-weight: normal;
  padding: 15px 0;
  white-space: pre-wrap;

  @media ${device.laptop} {
    font-size: 1.125rem;
    font-weight: 600;
    padding: 0;
  }
`

const Contact = ({
  title,
  subtitle,
  mail,
  col1,
  col2,
  image,
  color,
}: ContactInterface) => {
  const imgMobile = getImage(image.imageMobile)

  const imgDesktop = getImage(image.image)

  return (
    <Color color={getBackgroundColor(color)}>
      <Wrapper>
        {imgDesktop && (
          <ImageDesktop>
            <GatsbyImage
              image={imgDesktop}
              alt={image.alt}
              className="art-directed"
            />
          </ImageDesktop>
        )}
        <Grid>
          <TitleWrapper>
            <StyledTitle level={"h4"}>{title}</StyledTitle>
            {/* <StyledSubtitle>{subtitle}</StyledSubtitle> */}
          </TitleWrapper>
          <Mail>
            <Icon type={mail.icon} />
            <StyledLinkTo to={`mailto:${mail.text}`}>{mail.text}</StyledLinkTo>
          </Mail>
          {col1.map(({ name, text, icon }, idx: React.Key) => {
            return (
              <Phone key={idx} className={`phone-${idx}`}>
                <PhoneTitle>{name}</PhoneTitle>
                <Icon type={icon} />
                <StyledLinkTo action="call" to={text}>
                  {text}
                </StyledLinkTo>
              </Phone>
            )
          })}
          <Address>
            <AddressTitle>{col2.name}</AddressTitle>
            <Data>{col2.title}</Data>
            <AddressData className={"nip"}>{col2.nip}</AddressData>
            <AddressData>{col2.address}</AddressData>
            <StaticImageMobile>
              <StaticImage
                src="../../../images/logo-black-red-big.png"
                alt="logo"
                loading="eager"
                placeholder="none"
                objectFit="contain"
                imgClassName="logo"
              />
            </StaticImageMobile>
            {imgMobile && (
              <ImageMobile>
                <GatsbyImage
                  image={imgMobile}
                  alt={image.alt}
                  className="art-directed"
                />
              </ImageMobile>
            )}
          </Address>
        </Grid>
        <StaticImageDesktop>
          <StaticImage
            src="../../../images/logo-black-red-big.png"
            alt="logo"
            loading="eager"
            placeholder="none"
            objectFit="contain"
          />
        </StaticImageDesktop>
      </Wrapper>
    </Color>
  )
}

export default Contact
