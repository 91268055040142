import { Link as GatsbyLink } from "gatsby"
import React from "react"

export const parseTextLink = (text: string) => {
  const patternAll = /(?=\[link\]\()(.*?)(?<=\})/g
  const patternLink = /(?<=\()(.*?)(?=\))/g
  const patternText = /(?<=\{)(.*?)(?=\})/g

  const resultAll = text.match(patternAll)

  let result: { resultText: string; resultLink: string }[] = []

  resultAll &&
    resultAll.forEach((data) => {
      result.push({
        resultText: data.match(patternText)![0],
        resultLink: data.match(patternLink)![0],
      })
    })

  const splitText = text.split(patternAll)

  let index = 0
  return splitText.map((sentence, idx) => {
    if (resultAll![index] === sentence) {
      const linkComponent = (
        <MarkdownLink
          key={idx}
          href={result![index].resultLink}
          text={result![index].resultText}
        />
      )
      index++
      return linkComponent
    }
    return sentence
  })
}

const MarkdownLink = ({
  href,
  text,
}: {
  href: string | null
  text: string | null
}) => {
  if (href) {
    if (href.startsWith("/")) {
      return <GatsbyLink to={href}>{text || href}</GatsbyLink>
    }

    return <a href={href}>{text || href}</a>
  }

  return <></>
}
