import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"
import { device, getBackgroundColor, screenSize } from "../../GlobalStyles"
import Icon from "../../shared/Icon/Icon"
import Title from "../../shared/Title/Title"
import { PriceFactorInterface } from "./priceFactor.Interface"

const Color = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
  width: 100%;

  @media ${device.laptopM} {
    margin-top: 170px;
  }

  @media ${device.laptopL} {
    margin-top: 200px;
  }
`

const Image = styled.div`
  width: 100%;
  text-align: center;

  @media ${device.laptopM} {
    grid-column-start: 2;
    grid-row-start: 1;

    .art-directed {
      max-width: 570px;
      width: 100%;
      height: auto;
      position: relative;
      top: -90px;
    }
  }

  @media screen and (max-width: ${screenSize.tabletS - 1}px) {
    width: 100%;
    .art-directed {
      width: 100%;
      aspect-ratio: auto 360 / 196;
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 30px 0 60px 0;

  @media ${device.laptopM} {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0;
  }
`

const TitleWrapper = styled.div`
  padding: 0 45px;
  padding-bottom: 20px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;

  @media ${device.laptopM} {
    padding: 0;
    position: absolute;
    top: -170px;
  }

  @media ${device.laptopL} {
    top: -200px;
  }
`

const StyledTitle = styled(Title)`
  margin: 0 auto;

  @media screen and (max-width: ${screenSize.tabletS - 1}px) {
    font-size: 1.125rem;
  }

  @media ${device.laptopM} {
    max-width: 868px;
  }
`

const Grid = styled.div`
  display: grid;

  @media ${device.laptopM} {
    grid-template-columns: 1fr 1fr;
    padding: 40px;
    gap: 20px;
  }

  @media ${device.laptopL} {
    gap: 90px;
  }
`

const ItemsWrapper = styled.div`
  align-self: center;
  display: grid;
  row-gap: 20px;
  padding: 20px 20px 0;
  max-width: 570px;
  margin: 0 auto;

  @media ${device.laptopM} {
    grid-column-start: 1;
    grid-row-start: 1;
    row-gap: 50px;
  }
`

const ItemWrapper = styled.div`
  font-size: 0.875rem;

  display: grid;
  grid-template-columns: 50px auto;
  gap: 20px;
  align-items: center;
  justify-content: start;
  justify-items: end;

  @media ${device.laptop} {
    font-size: 1.25rem;
  }

  @media ${device.laptopM} {
    gap: 40px;
    justify-items: start;
  }
`

const StyledIcon = styled(Icon)`
  width: 30px;

  @media ${device.laptopM} {
    width: 42px;
  }
`

const PriceFactor = ({ color, title, image, items }: PriceFactorInterface) => {
  const img = withArtDirection(getImage(image.image)!, [
    {
      media: `(max-width: ${screenSize.tabletS - 1}px)`,
      image: getImage(image.imageMobile)!,
    },
  ])
  return (
    <Color color={getBackgroundColor(color)}>
      <Wrapper>
        <TitleWrapper>
          <StyledTitle level={"h1"}>{title}</StyledTitle>
        </TitleWrapper>
        <Grid>
          {img && (
            <Image>
              <GatsbyImage
                image={img}
                alt={image.alt}
                className="art-directed"
              />
            </Image>
          )}
          <ItemsWrapper>
            {items.map(({ item, icon }, idx: React.Key) => (
              <ItemWrapper key={idx}>
                <StyledIcon type={icon} />
                <div>{item}</div>
              </ItemWrapper>
            ))}
          </ItemsWrapper>
        </Grid>
      </Wrapper>
    </Color>
  )
}

export default PriceFactor
