import * as React from "react"
import FAQ from "../sections/FAQ/FAQ"
import ContactForm from "../sections/Form/contactForm"
import HomePageHero from "../sections/Hero/HomePageHero"
import ImageButtons from "../sections/ImageButtons/ImageButtons"
import Opinions from "../sections/Opinions/Opinions"
import Questions from "../sections/Questions/Questions"
import SeeMore from "../sections/SeeMore/SeeMore"
import Statistics from "../sections/Statistics/Statistics"
import Trust from "../sections/Trust/Trust"
import { HomeInterface } from "./home.interface"

const Home = ({
  home: {
    hero,
    questionsS,
    imageButtonsS,
    seeMoreS,
    statisticsS,
    opinionsS,
    formS,
    trustS,
    faqS,
  },
}: {
  home: HomeInterface
}) => {
  return (
    <>
      {hero.show && <HomePageHero {...hero} />}
      {questionsS.show && <Questions {...questionsS} />}
      {imageButtonsS.show && <ImageButtons {...imageButtonsS} />}
      {seeMoreS.show && <SeeMore {...seeMoreS} />}
      {statisticsS.show && <Statistics {...statisticsS} />}
      {opinionsS.show && <Opinions {...opinionsS} />}
      {formS.show && <ContactForm {...formS} />}
      {trustS.show && <Trust {...trustS} />}
      {faqS.show && <FAQ {...faqS} />}
    </>
  )
}

export default Home
