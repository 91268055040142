import * as React from "react"
import styled from "styled-components"
import { getBackgroundColor } from "../../GlobalStyles"
import Button from "../../shared/Button/Button"
import { FAQInterface } from "./faq.interface"

const Wrapper = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
`

const FAQ = ({ title, questions, button, color }: FAQInterface) => {
  return (
    <Wrapper color={getBackgroundColor(color)}>
      <h2>{title}</h2>
      {questions.map(({ title, answer }, idx: React.Key) => (
        <div key={idx}>
          <div>{title}</div>
          <div>{answer}</div>
        </div>
      ))}
      <Button {...button}>{button.title}</Button>
    </Wrapper>
  )
}

export default FAQ
