import * as React from "react"
import styled from "styled-components"
import { color, device, getBackgroundColor } from "../../GlobalStyles"
import Icon, { icon } from "../../shared/Icon/Icon"
import { OpinionsInterface } from "./opinions.interface"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Title from "../../shared/Title/Title"
import { StaticImage } from "gatsby-plugin-image"

const Color = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
  width: 100%;
`

const Wrapper = styled.div`
  padding: 40px 0 30px 0;
  width: 100%;
  text-align: center;
  margin: 0 auto;

  .slick-slider {
    max-width: 1240px;
    display: inline-grid;
    padding: 0 1%;
    overflow: hidden;

    @media ${device.tablet} {
      padding: 0 2%;
    }
  }

  .slick-initialized .slick-slide {
    padding: 0 30px;
    box-sizing: border-box;
  }

  .slick-dots li {
    transition: background 0.25s ease-in;
    opacity: 1;
    box-shadow: none;
    background: #ffffff;
    border: 1px solid ${color.decorationDark};
    border-radius: 50%;
    width: 14px;
    height: 14px;
    cursor: pointer;
    display: inline-block;
    margin: 0 15px;
  }

  .slick-dots li.slick-active {
    background: ${color.decorationDark};
  }

  .slick-dots li button:before {
    content: none;
  }

  @media ${device.laptop} {
    max-width: 1310px;
    width: 100%;
    padding: 140px 0;

    .slick-initialized .slick-slide {
      padding: 0 10px;
      box-sizing: border-box;
    }
  }
`

const TitleWrapper = styled.div`
  margin: 0 0 30px 0;
  display: grid;
  gap: 25px;
  box-sizing: border-box;

  @media ${device.laptop} {
    margin: 0 0 60px 0;
    padding: 0 20px;
    display: inline-grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: start;
    text-align: left;
    width: 100%;
    max-width: 1240px;
    gap: 40px;
  }
`

const Opinion = styled.div`
  font-family: Open Sans;
  max-width: 320px;
  background-color: #ffffff;
  border: 1px solid ${color.decorationDark};
  border-bottom: 4px solid ${color.decorationDark};

  display: inline-grid;
  margin: 0;
  text-align: center;
  grid-template-rows: 90px 70px 300px 55px;

  @media ${device.mobileM} {
    grid-template-rows: 90px 70px 270px 55px;
    max-width: 350px;
  }

  @media ${device.tablet} {
    max-width: 350px;
  }

  @media ${device.laptop} {
    grid-template-rows: 90px 80px 270px 55px;
    max-width: 400px;
    border: 1px solid ${color.decorationDark};
    border-bottom: 8px solid ${color.decorationDark};
  }

    @media ${device.laptopL} {
      grid-template-rows: 90px 100px 300px 55px;
    }
  }
`

const Author = styled.div`
  border-bottom: 1px solid ${color.decorationDark};
  font-weight: 600;
  font-size: 1.15rem;
  padding: 30px 45px;
  text-align: left;

  @media ${device.laptopL} {
    font-size: 1.25rem;
  }
`

const Stars = styled.div`
  padding: 20px 0 20px 0;

  @media ${device.laptop} {
    padding: 30px 0 20px 0;
  }

  @media ${device.laptopL} {
    padding: 30px 0 40px 0;
  }
`

const Text = styled.div`
  padding: 0px 45px;
  font-size: 0.8rem;

  span {
    display: block;
    padding-top: 10px;
    font-size: 0.875rem;
  }

  @media ${device.mobileM} {
    font-size: 0.875rem;
  }

  @media ${device.laptop} {
    font-size: 0.875rem;
  }

  @media ${device.laptopL} {
    font-size: 1.125rem;
  }
`

const Button = styled.button`
  position: absolute;
  z-index: 1;
  bottom: 50%;
  background: none;
  border: none;
  text-align: center;

  span {
    color: ${color.decorationDark};
    font-size: 40px;
  }
`

const ButtonPrev = styled(Button)`
  left: -10px;

  @media (min-width: 480px) {
    left: 0;
    right: 420px;
  }

  @media ${device.tablet} {
    right: unset;
    left: -10px;
  }

  @media ${device.laptopL} {
    right: unset;
    left: -20px;
  }
`

const ButtonNext = styled(Button)`
  right: -10px;

  @media (min-width: 480px) {
    right: 0;
    left: 420px;
  }

  @media ${device.tablet} {
    left: unset;
    right: -10px;
  }

  @media ${device.laptopL} {
    left: unset;
    right: -20px;
  }
`

const ImageWrapper = styled.div`
  text-align: right;
  padding: 0 25px;
`

const Opinions = ({ title, opinions, color }: OpinionsInterface) => {
  const google = "../../../images/icon_google.png"
  const facebook = "../../../images/icon_facebook.png"

  const googleImage = (
    <StaticImage
      src={google}
      alt="google_icon"
      loading="eager"
      placeholder="none"
      objectFit="contain"
    />
  )

  const facebookImage = (
    <StaticImage
      src={facebook}
      alt="facebook_icon"
      loading="eager"
      placeholder="none"
      objectFit="contain"
    />
  )

  function SamplePrevArrow(props: any) {
    const { onClick } = props
    return (
      <ButtonPrev onClick={onClick}>
        <span className="material-icons material-symbols-outlined">
          chevron_left
        </span>
      </ButtonPrev>
    )
  }

  function SampleNextArrow(props: any) {
    const { onClick } = props
    return (
      <ButtonNext onClick={onClick}>
        <span className="material-icons material-symbols-outlined">
          chevron_right
        </span>
      </ButtonNext>
    )
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  }

  return (
    <Color color={getBackgroundColor(color)}>
      <Wrapper>
        <TitleWrapper>
          <StaticImage
            src="../../../images/logo-blue.png"
            alt="logo"
            loading="eager"
            style={{
              position: "relative",
              height: "100%",
              maxHeight: "90px",
              inset: 0,
            }}
            placeholder="none"
            objectFit="contain"
          />
          <Title level="h2" style={{ margin: "0" }}>
            {title}
          </Title>
        </TitleWrapper>
        <Slider {...settings}>
          {opinions.map(
            ({ starsNumber, text, author, iconName }, idx: React.Key) => (
              <div key={idx}>
                <Opinion>
                  <Author>{author}</Author>
                  <Stars>
                    <Icon type={icon.star} starsNumber={starsNumber} />
                  </Stars>
                  <Text>{text}</Text>
                  <ImageWrapper>
                    {iconName === "facebook" ? facebookImage : googleImage}
                  </ImageWrapper>
                </Opinion>
              </div>
            )
          )}
        </Slider>
      </Wrapper>
    </Color>
  )
}

export default Opinions
