import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/SEO"
import Home from "../components/Home/Home"
import BusinessOffer from "../components/BusinessOffer/BusinessOffer"
import BisnessOfferRelocation from "../components/BusinessOffer/Relocation/Relocation"
import { HomeInterface } from "../components/Home/home.interface"
import { BusinessOfferInterface } from "../components/BusinessOffer/businessOffer.interface"
import { RelocationInterface as BisnessOfferRelocationInterface } from "../components/BusinessOffer/Relocation/relocation.interface"
import { StorageInterface as BisnessOfferStorageInterface } from "../components/BusinessOffer/Storage/storage.interface"
import BisnessOfferStorage from "../components/BusinessOffer/Storage/Storage"
import { TransportInterface as BusinessOfferTransportInterface } from "../components/BusinessOffer/Transport/transport.interface"
import BusinessOfferTransport from "../components/BusinessOffer/Transport/Transport"
import Transport from "../components/Transport/Transport"
import { RelocationInterface } from "../components/Relocation/relocation.interface"
import Relocation from "../components/Relocation/Relocation"
import { StorageInterface } from "../components/Storage/storage.interface"
import Storage from "../components/Storage/Storage"
import { TransportInterface } from "../components/Transport/transport.interface"
import Price from "../components/Price/Price"
import { PriceInterface } from "../components/Price/price.interface"
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy"
import { PrivacyPolicyInterface } from "../components/PrivacyPolicy/privacyPolicy.interface"
import { ContactInterface } from "../components/Contact/contact.interface"
import Contact from "../components/Contact/Contact"
import { ReferencesInterface } from "../components/References/references.interface"
import References from "../components/References/References"
import { ContactFormContext } from "../store/contact-form-ref-context"

interface PageInterface {
  data: {
    page: {
      body: string
      frontmatter: {
        title: string
        type?: PageType
        seo?: {
          title: string
          description: string
          article?: "true" | "false"
          keywords?: string
        }
        home: HomeInterface
        businessOffer: BusinessOfferInterface
        businessOfferRelocation: BisnessOfferRelocationInterface
        businessOfferStorage: BisnessOfferStorageInterface
        businessOfferTransport: BusinessOfferTransportInterface
        relocation: RelocationInterface
        transport: TransportInterface
        storage: StorageInterface
        price: PriceInterface
        privacyPolicy: PrivacyPolicyInterface
        contact: ContactInterface
        references: ReferencesInterface
      }
    }
  }
  location: Location
}

enum PageType {
  home = "home",
  businessOffer = "business-offer",
  businessOfferRelocation = "business-offer-relocation",
  businessOfferStorage = "business-offer-storage",
  businessOfferTransport = "business-offer-transport",
  relocation = "relocation",
  storage = "storage",
  transport = "transport",
  price = "price",
  privacyPolicy = "privacyPolicy",
  contact = "contact",
  references = "references",
}

const Page = ({ data: { page }, location }: PageInterface) => {
  const { type, seo } = page.frontmatter
  const isArticle = seo?.article === "true"
  const contactFormSection = React.useRef<HTMLInputElement>(null)

  const component = (() => {
    switch (type) {
      case PageType.home:
        return <Home {...page.frontmatter} />
      case PageType.businessOffer:
        return <BusinessOffer {...page.frontmatter} />
      case PageType.businessOfferRelocation:
        return <BisnessOfferRelocation {...page.frontmatter} />
      case PageType.businessOfferStorage:
        return <BisnessOfferStorage {...page.frontmatter} />
      case PageType.businessOfferTransport:
        return <BusinessOfferTransport {...page.frontmatter} />
      case PageType.relocation:
        return <Relocation {...page.frontmatter} />
      case PageType.storage:
        return <Storage {...page.frontmatter} />
      case PageType.transport:
        return <Transport {...page.frontmatter} />
      case PageType.price:
        return <Price {...page.frontmatter} />
      case PageType.privacyPolicy:
        return <PrivacyPolicy {...page.frontmatter} body={page.body} />
      case PageType.contact:
        return <Contact {...page.frontmatter} />
      case PageType.references:
        return <References {...page.frontmatter} />
      default:
        return <></>
    }
  })()

  return (
    <ContactFormContext.Provider
      value={{
        sectionRef: contactFormSection,
      }}
    >
      <Layout location={location}>
        <SEO
          title={seo?.title}
          description={seo?.description}
          article={isArticle}
          keywords={seo?.keywords}
        />

        {component}
      </Layout>
    </ContactFormContext.Provider>
  )
}

export default Page

export const query = graphql`
  query ($id: String) {
    page: mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        type
        seo {
          ...SeoFragment
        }
        ...HomeFragment
        ...BusinessOfferFragment
        ...BusinessOfferRelocationFragment
        ...BusinessOfferStorageFragment
        ...BusinessOfferTransportFragment
        ...RelocationFragment
        ...StorageFragment
        ...TransportFragment
        ...PriceFragment
        ...PrivacyPolicyFragment
        ...ContactFragment
        ...ReferencesFragment
      }
    }
  }
`
