import * as React from "react"
import styled from "styled-components"
import { color, device, getBackgroundColor } from "../../GlobalStyles"
import Icon, { icon } from "../../shared/Icon/Icon"
import { StatisticsInterface } from "./statistics.interface"

const Color = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
  width: 100%;
`

const Wrapper = styled.div`
  padding: 40px 0 30px 0;
  width: 100%;
  text-align: center;

  @media ${device.laptop} {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    padding: 140px 0;
  }
`

const Advantages = styled.div`
  width: 100%;
  text-align: center;
`

const Advantage = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  box-sizing: border-box;
  font-size: 1rem;

  @media ${device.laptop} {
    font-weight: 700;
    font-size: 1.3rem;
    display: inline-grid;
    padding: 0 35px;
  }

  @media ${device.laptopL} {
    font-size: 1.5rem;
  }
`

const StyledIcon = styled(Icon)`
  width: 18px;
  height: 14px;
  margin-right: 5px;

  @media ${device.laptop} {
    width: 45px;
    height: 34px;
    margin-right: 20px;
  }
`

const Hr = styled.hr`
  border: 0;
  border-bottom: 1px solid ${color.decoration};
  margin: 5px auto 40px auto;
  max-width: 150px;
  width: 45%;

  @media ${device.laptop} {
    margin: 45px auto 40px auto;
    max-width: 680px;
    width: 60%;
  }
`

const Cards = styled.div`
  width: 100%;
  padding: 0 5%;
  box-sizing: border-box;

  @media ${device.laptop} {
    display: inline-grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding: 0 1%;
  }
`

const Card = styled.div`
  max-width: 320px;
  min-height: 195px;
  border: 1px solid ${color.primary};
  border-bottom: 4px solid ${color.primary};

  display: grid;
  grid-template-rows: 60px auto auto;
  text-align: center;
  margin: 10px auto;

  @media ${device.laptop} {
    max-width: 400px;
    min-height: 255px;
    border-bottom: 8px solid ${color.primary};
    grid-template-rows: 110px auto auto;
    margin: 0;
  }
`

const Icons = styled.div`
  align-self: end;
`

const CardIcon = styled(Icon)`
  width: 45px;
  height: 35px;

  @media ${device.laptop} {
    width: auto;
    height: auto;
  }
`

const Number = styled.div`
  font-family: Open Sans;
  font-weight: 800;
  font-size: 2.375rem;
  padding: 5px 0;

  @media ${device.laptop} {
    padding: 15px 0;
  }
`
const Text = styled.div`
  font-family: Open Sans;
  font-weight: 600;
  max-width: 260px;
  margin 0 auto;
  padding-bottom: 20px;
  font-size: 1.125rem;

  @media ${device.laptop} {
    padding-bottom: 35px;
  }
`

const Statistics = ({ advantages, cards, color }: StatisticsInterface) => {
  return (
    <Color color={getBackgroundColor(color)}>
      <Wrapper>
        <Advantages>
          {advantages.map(({ text }, idx: React.Key) => (
            <Advantage key={idx}>
              <StyledIcon type={icon.approve} />
              <span>{text}</span>
            </Advantage>
          ))}
        </Advantages>
        <Hr />
        <Cards>
          {cards.map(({ text, number, icon }, idx: React.Key) => (
            <Card key={idx}>
              <Icons>
                <CardIcon type={icon} />
              </Icons>
              <Number>{number}</Number>
              <Text>{text}</Text>
            </Card>
          ))}
        </Cards>
      </Wrapper>
    </Color>
  )
}

export default Statistics
