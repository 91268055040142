import { formdata, ValuesInterface } from "./contactForm.interface"

export const validate = (values: ValuesInterface) => {
  const errors: any = {}

  if (!values.name) {
    errors.name = formdata.error.required
  } else if (values.name.length < 4 || values.name.trim() === "") {
    errors.name = formdata.error.name
  }

  if (!values.email) {
    errors.email = formdata.error.required
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = formdata.error.email
  }

  if (values.phone.length > 3 && values.phone.length < 12) {
    errors.phone = "Niepoprawny numer telefonu"
  }

  if (!values.text) {
    errors.text = formdata.error.required
  } else if (values.text.length < 4 || values.text.trim() === "") {
    errors.text = formdata.error.text
  }

  if (values.serviceType.length === 0) {
    errors.serviceType = formdata.error.required
  }

  if (values.agreement === false) {
    errors.agreement = formdata.error.required
  }

  return errors
}
