import * as React from "react"
import { StorageInterface } from "./storage.interface"
import ContactForm from "../../sections/Form/contactForm"
import Hero from "../../sections/Hero/Hero"
import SeeMoreBig from "../../sections/SeeMore/SeeMoreBig"
import StorageInfo from "../../sections/StorageInfo/StorageInfo"

const Storage = ({
  businessOfferStorage: { hero, storageInfoS, seeMoreBigS, formS },
}: {
  businessOfferStorage: StorageInterface
}) => {
  return (
    <>
      {hero.show && <Hero {...hero} />}
      {storageInfoS.show && <StorageInfo {...storageInfoS} />}
      {seeMoreBigS.show && <SeeMoreBig {...seeMoreBigS} />}
      {formS.show && <ContactForm {...formS} />}
    </>
  )
}

export default Storage
