import * as React from "react"
import styled from "styled-components"
import { color, device, getBackgroundColor } from "../../GlobalStyles"
import Button from "../../shared/Button/Button"
import Icon from "../../shared/Icon/Icon"
import { StorageInfoInterface } from "./storageInfo.interface"

const Color = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
  width: 100%;
`

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  padding: 40px 20px;
  margin: 0 auto;
  justify-content: center;
  row-gap: 20px;

  @media ${device.laptopM} {
    max-width: 1320px;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 30px;
    padding: 70px 40px 120px 40px;
  }
`

const Column2 = styled.div`
  position: relative;
  grid-row-start: 2;
  max-width: 322px;
  width: 100%;
  height: 180px;
  margin: 0 auto;

  @media ${device.laptopM} {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 4;
    height: 100%;
    width: 100%;
    max-width: 100%;
  }
`

const Title = styled.div`
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
  grid-row-start: 1;

  @media ${device.tablet} {
    font-weight: bold;
    font-size: 1.2rem;
  }

  @media ${device.laptopM} {
    font-size: 1.5rem;
    grid-column-start: 1;
    grid-row-start: 1;
  }
`

const Subtitle = styled.div`
  font-size: 0.875rem;
  paddin: 0;
  margin: 0;
  grid-row-start: 3;

  @media ${device.laptop} {
    font-size: 1.125rem;
  }

  @media ${device.laptopM} {
    grid-column-start: 1;
    grid-row-start: 2;
  }
`

const StyledButton = styled(Button)`
  justify-self: center;
  grid-row-start: 4;

  @media ${device.laptopM} {
    justify-self: left;
    grid-column-start: 1;
    grid-row-start: 3;
  }
`

const Box = styled.div`
  width: 54.5%;
  height: 106px;

  position: absolute;
  border: 1px solid ${color.primary};
  border-bottom: 8px solid ${color.primary};
  border-radius: 10px 10px 0 0;
  background-color: #ffffff;
  text-align: center;

  display: inline-grid;
  gap: 10px;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;

  @media ${device.laptopM} {
    width: 57.7%;
    height: 68%;
    gap: 35px;
  }

  :first-child {
    top: 0;
    right: 0;
  }

  :last-child {
    left: 0;
    bottom: 0;
  }
`

const CardIcon = styled(Icon)`
  width: 37px;
  height: 37px;

  @media ${device.laptopM} {
    width: 65px;
    height: 65px;
  }
`

const BoxTitle = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 0.813rem;
  max-width: 132px;
  justify-self: center;

  @media ${device.laptopM} {
    font-size: 1.25rem;
    max-width: 172px;
  }
`

const StorageInfo = ({
  title,
  subtitle,
  cards,
  button,
  color,
}: StorageInfoInterface) => {
  return (
    <Color color={getBackgroundColor(color)}>
      <Wrapper>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <StyledButton {...button}>{button.title}</StyledButton>
        <Column2>
          {cards.map(({ icon, title }, idx: React.Key) => (
            <Box key={idx}>
              <CardIcon type={icon} />
              <BoxTitle>{title}</BoxTitle>
            </Box>
          ))}
        </Column2>
      </Wrapper>
    </Color>
  )
}

export default StorageInfo
