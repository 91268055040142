import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"
import { getBackgroundColor } from "../../GlobalStyles"
import { TrustInterface } from "./trust.interface"

const Wrapper = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
`

const Trust = ({ title, logos, color }: TrustInterface) => {
  return (
    <Wrapper color={getBackgroundColor(color)}>
      <h2>{title}</h2>
      {logos.map(({ image }, idx: React.Key) => {
        const img = getImage(image.image)
        return (
          <div key={idx}>
            {img && <GatsbyImage image={img} alt={image.alt} />}
          </div>
        )
      })}
    </Wrapper>
  )
}

export default Trust
