import * as React from "react"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import Button from "../../shared/Button/Button"
import { SeeMoreInterface } from "./seeMore.interface"
import styled from "styled-components"
import { device, getBackgroundColor, screenSize } from "../../GlobalStyles"
import Title from "../../shared/Title/Title"

const Color = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
  width: 100%;
`

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  text-align: center;
`

const Image = styled.div`
  grid-row-start: 1;
  grid-column-start: 1;

  @media ${device.laptop} {
    height: 422px;
  }

  .gatsby-image-wrapper {
    height: 100%;
  }

  @media screen and (max-width: ${screenSize.laptop - 1}px) {
    .art-directed {
      height: 255px;
    }
  }
`

const Container = styled.div`
  display: grid;
  justify-items: center;
  grid-column-start: 1;
  grid-row-start: 1;
  z-index: 1;
`

const StyledTitle = styled(Title)`
  color: #ffffff;
  margin: 0 0 20px 0;
  box-sizing: border-box;

  @media ${device.mobileM} {
    margin: 0 0 30px 0;
  }

  @media ${device.tabletS} {
    margin: 0 0 40px 0;
    padding: 0 40px;
  }

  @media ${device.laptopM} {
    margin: 0 0 50px 0;
  }
`

const SeeMore = ({ title, button, image, color }: SeeMoreInterface) => {
  const img = withArtDirection(getImage(image.image)!, [
    {
      media: `(max-width: ${screenSize.laptop - 1}px)`,
      image: getImage(image.imageMobile)!,
    },
  ])

  return (
    <Color color={getBackgroundColor(color)}>
      <Wrapper>
        {img && (
          <Image>
            <GatsbyImage image={img} alt={image.alt} className="art-directed" />
          </Image>
        )}
        <Container>
          <StyledTitle level="h2">{title}</StyledTitle>
          <Button {...button}>{button.title}</Button>
        </Container>
      </Wrapper>
    </Color>
  )
}

export default SeeMore
