import { buttonType } from "../../shared/Button/ButtonType"

export type formType = "person" | "firm"

export interface ValuesInterface {
  name: string
  email: string
  phone: string
  countryCode: string
  text: string
  serviceType: Array<string | null>
  date: string
  dateTo?: string
  agreement: boolean
}

export interface ContactFormInterface {
  show?: boolean
  title: string
  color: string
  type: formType
  button?: {
    title: string
    btnStyle: buttonType
  }
  agreementText?: string
  formName: string
}

export const formdata = {
  error: {
    required: "wymagane",
    name: "pole powinno zawierać minimum 3 znaki",
    email: "niepoprawny mail",
    phone: "niepoprawny numer telefonu",
    text: "pole powinno zawierać minimum 3 znaki",
  },
  personName: {
    text: null,
    placeholder: "Imię i nazwisko",
  },
  firmName: {
    text: null,
    placeholder: "Nazwa firmy",
  },
  email: {
    text: null,
    placeholder: "Mail",
  },
  phone: {
    text: "Numer telefonu (opcjonalnie)",
  },
  text: {
    text: "Treść wiadomości",
    placeholder: "",
  },
  serviceType: {
    text: "Rodzaj usługi",
    values: ["Przeprowadzka", "Magazynowanie", "Transport"],
  },
  date: {
    text: null,
    placeholder: "Wybierz termin (opcjonalnie)",
  },
  agreement: {
    text: "Wyrażam zgodę na użycie powyższych danych zgodnie z obowiązującą na stronie polityką prywatności.",
  },
  button: {
    title: "Wyślij",
  },
}
