import * as React from "react"
import styled from "styled-components"
import { useFormContext } from "../../../store/contact-form-ref-context"
import { buttonAction } from "../../../utils/buttonAction"
import { color, device, getBackgroundColor } from "../../GlobalStyles"
import Button from "../../shared/Button/Button"
import Title from "../../shared/Title/Title"
import { QuestionsInterface } from "./questions.interface"

const Color = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
  width: 100%;
`

const Wrapper = styled.div`
  padding: 0 20px;
  text-align: center;
`

const H2 = styled(Title)`
  margin: 40px 0 25px 0;

  @media ${device.laptop} {
    margin: 80px 0 30px 0;
  }
`
const UL = styled.ul`
  margin: 0 auto;
  padding: 0;
  max-width: 500px;

  @media ${device.tablet} {
    max-width: 700px;
  }

  @media ${device.laptop} {
    max-width: 900px;
  }

  @media ${device.desktop} {
    max-width: 900px;
  }
`
const LI = styled.li`
  display: block;
  margin-bottom: 15px;
  list-style-type: none;

  @media ${device.tablet} {
    margin-bottom: 20px;
  }

  :nth-child(n + 1) {
    text-align: right;
    div {
      border-radius: 30px 30px 0px 30px;
    }
  }

  :nth-child(2n + 1) {
    text-align: left;
    div {
      border-radius: 30px 30px 30px 0px;
    }
  }
`

const Question = styled.div`
  display: inline-block;
  min-width: 250px;
  max-width: 90%;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 0.875rem;

  background-color: ${color.light};
  border: 1px solid ${color.veryLight};
  padding: 10px;

  text-align: center;

  @media ${device.tablet} {
    min-width: 500px;
    padding: 20px;
    max-width: none;
  }

  @media ${device.laptop} {
    font-size: 1.35rem;
    min-width: 690px;
  }

  @media ${device.desktop} {
    font-size: 1.563rem;
  }
`

const Text = styled.div`
  font-family: Open Sans;
  max-width: 900px;
  margin: 25px auto 40px auto;

  @media ${device.laptop} {
    margin: 80px auto 50px auto;
    font-size: 1.25rem;
  }

  @media ${device.laptopL} {
    font-size: 1.25rem;
  }

  @media ${device.desktop} {
    font-size: 1.25rem;
  }
`
const ButtonsPosition = styled.div`
  display: grid;
  justify-items: center;
  gap: 10px;
  margin-bottom: 35px;

  @media ${device.tabletS} {
    display: block;
    margin-bottom: 80px;
  }
`
const RedButton = styled(Button)``
const BlueButton = styled(Button)`
  @media ${device.tabletS} {
    margin-left: 20px;
  }
`
const Questions = ({
  title,
  questions,
  text,
  primaryBtn,
  secondaryBtn,
  color,
}: QuestionsInterface) => {
  const contactFormSection = useFormContext()

  return (
    <Color color={getBackgroundColor(color)}>
      <Wrapper>
        <H2 level="h2">{title}</H2>
        <UL>
          {questions.map(({ text }, idx: React.Key) => (
            <LI key={idx}>
              <Question>{text}</Question>
            </LI>
          ))}
        </UL>
        <Text>{text}</Text>
        <ButtonsPosition>
          {contactFormSection && (
            <RedButton
              {...primaryBtn}
              onClick={buttonAction(
                contactFormSection.sectionRef,
                primaryBtn.action
              )}
            >
              {primaryBtn.title || ""}
            </RedButton>
          )}
          {contactFormSection && (
            <BlueButton
              {...secondaryBtn}
              onClick={buttonAction(
                contactFormSection.sectionRef,
                secondaryBtn.action
              )}
            >
              {secondaryBtn.title || ""}
            </BlueButton>
          )}
        </ButtonsPosition>
      </Wrapper>
    </Color>
  )
}

export default Questions
