import * as React from "react"
import styled from "styled-components"
import { getBackgroundColor } from "../../GlobalStyles"
import Reference from "../Reference/Reference"
import { ReferencesInterface } from "./references.interface"

const Color = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
  width: 100%;
  text-align: center;
  padding-top: 70px;
`

const StyledReference = styled(Reference)`
  padding: 0;
  padding-bottom: 70px;
`

const References = ({ color, references }: ReferencesInterface) => {
  return (
    <Color color={getBackgroundColor(color)}>
      {references.map((props, idx: React.Key) => {
        return (
          <StyledReference {...props} color={color} small={true} key={idx} />
        )
      })}
    </Color>
  )
}

export default References
