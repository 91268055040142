import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"
import { color, device, getBackgroundColor } from "../../GlobalStyles"
import Icon, { icon } from "../../shared/Icon/Icon"
import Title from "../../shared/Title/Title"
import { PackageInterface } from "./package.interface"

const Color = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
  width: 100%;
`

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 40px 0;

  @media ${device.tablet} {
    max-width: 1280px;
    margin: 0 auto;
    padding: 70px 20px 130px 20px;
  }
`

const StyledTitle = styled(Title)`
  text-align: center;
  padding: 0 20px;
`

const Subtitle = styled.div`
  font-size: 0.875rem;
  text-align: center;
  max-width: 640px;
  margin: 0 auto;
  padding: 0 20px;

  @media ${device.laptop} {
    font-size: 1.25rem;
  }
`

const Image = styled.div`
  .art-directed {
    width: 140px;
    height: auto;
  }

  text-align: center;
  position: relative;
  top: -14px;

  @media ${device.mobileL} {
    top: -27px;

    .art-directed {
      width: 271px;
      height: auto;
    }
  }
`

const PackagesWrapper = styled.div`
  display: grid;
  padding: 0 20px;
  gap: 60px;
  padding-top: 50px;

  @media ${device.mobileL} {
    gap: 60px;
    margin: 0 auto;
    padding-top: 97px;
  }

  @media ${device.laptopM} {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
`

const Items = styled.div`
  border: 1px solid ${color.decoration};
  border-radius: 10px;
  max-width: 400px;
  margin: 0 auto;
`

const ItemTitle = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 1.125rem;
  text-align: center;
  padding: 15px 20px 25px;

  @media ${device.mobileL} {
    padding: 28px 20px 35px 20px;
  }

  @media ${device.laptop} {
    font-size: 1.563rem;
  }
`

const Item = styled.div<{ isInclude: boolean }>`
  font-weight: 600;
  font-size: 0.875rem;
  opacity: ${(props) => (props.isInclude ? "1" : "0.6")};

  @media ${device.laptop} {
    font-size: 1.125rem;
  }
`

const ItemsWrapper = styled.div`
  display: grid;
  gap: 20px;
  padding-bottom: 20px;

  @media ${device.mobileL} {
    gap: 30px;
    padding-bottom: 60px;
  }
`

const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  gap: 15px;
  justify-items: start;
  justify-content: start;
  align-items: center;
  margin: 0 30px;
  border-bottom: 1px solid ${color.decorationLight};
  padding-bottom: 7px;

  @media ${device.mobileL} {
    gap: 20px;
    padding-bottom: 10px;
  }
`

const StyledIcon = styled(Icon)`
  justify-self: center;
`

const Package = ({ color, title, subtitle, cards }: PackageInterface) => {
  return (
    <Color color={getBackgroundColor(color)}>
      <Wrapper>
        <StyledTitle level="h1">{title}</StyledTitle>
        <Subtitle>{subtitle}</Subtitle>
        <PackagesWrapper>
          {cards.map(({ title, image, items }, idx: React.Key) => {
            const img = getImage(image.image)
            return (
              <Items key={idx}>
                {img && (
                  <Image>
                    <GatsbyImage
                      image={img}
                      alt={image.alt}
                      className="art-directed"
                    />
                  </Image>
                )}
                <ItemTitle>{title}</ItemTitle>
                <ItemsWrapper>
                  {items.map(({ item, include }, idx: React.Key) => {
                    const type = include ? icon.yes : icon.no
                    return (
                      <ItemWrapper key={idx}>
                        <StyledIcon type={type} />
                        <Item isInclude={include}>{item}</Item>
                      </ItemWrapper>
                    )
                  })}
                </ItemsWrapper>
              </Items>
            )
          })}
        </PackagesWrapper>
      </Wrapper>
    </Color>
  )
}

export default Package
