import * as React from "react"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import Button from "../../shared/Button/Button"
import { SeeMoreBigInterface } from "./seeMore.interface"
import styled from "styled-components"
import { device, getBackgroundColor, screenSize } from "../../GlobalStyles"
import Title from "../../shared/Title/Title"
import Icon, { icon } from "../../shared/Icon/Icon"

const Color = styled.div<{ color: { bg: string; text: string } }>`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.text};
  width: 100%;
`

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  text-align: center;
`

const Image = styled.div`
  grid-row-start: 1;
  grid-column-start: 1;
  height: 700px;

  .gatsby-image-wrapper {
    height: 100%;
  }

  @media screen and (max-width: ${screenSize.tabletS - 1}px) {
    height: 500px;
    .art-directed {
      width: 100%;
    }
  }
`

const Container = styled.div`
  display: grid;
  gap: 30px;
  justify-items: center;
  grid-column-start: 1;
  grid-row-start: 1;
  z-index: 1;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;

  @media ${device.mobileM} {
    gap: 30px;
  }

  @media ${device.tabletS} {
    gap: 50px;
  }
`

const StyledTitle = styled(Title)`
  color: #ffffff;
  margin: 0;
  padding: 0 20px;

  @media ${device.tablet} {
    font-size: 1.5rem;
    padding: 0 50px;
  }
`

const Phone = styled.div`
  font-size: 1.125rem;
  letter-spacing: 0.119rem;
  color: #ffffff;
  font-weight: bold;

  @media ${device.mobileL} {
    font-size: 1.5rem;
  }

  @media ${device.tablet} {
    font-size: 1.5rem;
  }

  @media ${device.laptopL} {
    font-size: 2.188rem;
  }

  @media ${device.desktop} {
    font-size: 2.375rem;
  }
`

const StyledIcon = styled(Icon)`
  height: auto;

  @media ${device.mobileM} {
    width: 30px;
  }

  @media ${device.laptopL} {
    width: 40px;
  }

  @media ${device.desktop} {
    width: 50px;
  }
`

const PhoneWrapper = styled.div`
  display: none;

  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 25px;
    padding: 0 50px;
  }
`

const StyledButton = styled(Button)`
  @media ${device.mobileL} {
    display: none;
  }
`

const Line = styled.div`
  width: 90%;
  border-top: 2px solid #ffffff;

  @media ${device.laptopL} {
    width: 100%;
  }
`

const SeeMoreBig = ({
  title1,
  title2,
  phone,
  button,
  buttonCall,
  image,
  color,
}: SeeMoreBigInterface) => {
  const img = withArtDirection(getImage(image.image)!, [
    {
      media: `(max-width: ${screenSize.tabletS - 1}px)`,
      image: getImage(image.imageMobile)!,
    },
  ])
  return (
    <Color color={getBackgroundColor(color)}>
      <Wrapper>
        {img && (
          <Image>
            <GatsbyImage image={img} alt={image.alt} className="art-directed" />
          </Image>
        )}
        <Container>
          <StyledTitle level="h2">{title1}</StyledTitle>
          <StyledButton {...buttonCall}>{buttonCall.title}</StyledButton>
          <PhoneWrapper>
            <StyledIcon type={icon.phoneRed} />
            <Phone>{phone}</Phone>
          </PhoneWrapper>
          <Line />
          <StyledTitle level="h2">{title2}</StyledTitle>
          <Button {...button}>{button.title}</Button>
        </Container>
      </Wrapper>
    </Color>
  )
}

export default SeeMoreBig
