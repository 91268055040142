import * as React from "react"
import { ReferencesInterface } from "./references.interface"
import Hero from "../sections/Hero/Hero2"
import ReferencesS from "../sections/References/References"

const References = ({
  references: { hero, referencesS },
}: {
  references: ReferencesInterface
}) => {
  return (
    <>
      {hero.show && <Hero {...hero} />}
      {referencesS.show && <ReferencesS {...referencesS} />}
    </>
  )
}

export default References
