import * as React from "react"
import { StorageInterface } from "./storage.interface"
import ContactForm from "../sections/Form/contactForm"
import Hero from "../sections/Hero/Hero"
import SeeMore from "../sections/SeeMore/SeeMore"
import StorageInfo from "../sections/StorageInfo/StorageInfo"

const Storage = ({
  storage: { hero, storageInfoS, seeMoreS, formS },
}: {
  storage: StorageInterface
}) => {
  return (
    <>
      {hero.show && <Hero {...hero} />}
      {storageInfoS.show && <StorageInfo {...storageInfoS} />}
      {seeMoreS.show && <SeeMore {...seeMoreS} />}
      {formS.show && <ContactForm {...formS} />}
    </>
  )
}

export default Storage
